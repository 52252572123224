import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { forEach, map, reduce } from 'lodash';
import FullCalendar from 'rc-calendar/lib/FullCalendar';
import Select from 'rc-select';
import rcCalendarFr from 'rc-calendar/lib/locale/fr_FR';
import rcCalendarEn from 'rc-calendar/lib/locale/en_US';
import moment from 'moment';
import 'moment/min/locales';
import styled from 'styled-components';
import { Button, Empty } from 'antd';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import CalendarAddPeriodForm from './CalendarAddPeriodForm';
import { CalendarsTabContext, CalendarsTabContextProvider } from '../../../contexts/calendar/CalendarsTabContext';
import CalendarAddExclusionForm from './CalendarAddExclusionForm';
import LoadingSpin from '../../utils/LoadingSpin';
import { notificationError } from '../../../helpers/notification';
import AuthService from '../../../services/auth.service';
import { generateString } from '../../../helpers/string-helper';

const CalendarWrapper = styled.div`
    width: calc(100% / 3 - 0.25rem);
    box-shadow: 0 1px 5px #ccc;
    border: 1px solid #ccc;

    .rc-calendar-full {
        max-width: 100%;
        border: 0;
        box-shadow: none;
        

        .rc-calendar-column-header {
            background-color: black;
            opacity: 1;
            color: white;
        }

        .rc-calendar-full-header {
            display: none;
        }

        .rc-calendar-date {
            font-size: 10px;
            height: 18px;
            width: 18px;
            line-height: 18px;

            &.day-excluded {
                background-image: linear-gradient(
                    45deg,
                    #fff 25%,
                    #bbb 25%,
                    #bbb 50%,
                    #fff 50%,
                    #fff 75%,
                    #bbb 75%,
                    #bbb 100%
                );
                background-size: 5px 5px;
            }
        }

        .rc-calendar-selected-day {
            .rc-calendar-date {
                background-color: transparent;
            }
        }
    }
`;

const CalendarYearLabel = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #fbbe3f;
`;

const MonthWeekNumbers = styled.div`
    padding-bottom: 2px;
`;

const WeekNumberHeader = styled.div`
    height: 30px;
    background-color: #000;
`;

const WeekNumber = styled.span`
    line-height: 20px;
    border-right: 1px solid #828282;

    &:first-of-type {
        line-height: 18px;
    }
`;

const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              red: parseInt(result[1], 16),
              green: parseInt(result[2], 16),
              blue: parseInt(result[3], 16),
          }
        : null;
};

const chooseDayNumberColor = (hexColor) => {
    const rgb = hexToRgb(hexColor.slice(0, 7)); // remove transparent info

    if (rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186) {
        return '#666';
    }

    return '#fff';
};

const calendarContainerClass = 'admin-calendar-container';

const CalendarYearCalendars = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [calendarYear, setCalendarYear] = useState(moment().startOf('year'));
    const { selectedCalendar } = useContext(CalendarsTabContext);
    const { setModalContent, periodsTab, periods } = useContext(CalendarMenuContext);
    let selectedStartDate;
    const { isAdmin } = AuthService.getCurrentUser();
    let fullCalendarLocale = rcCalendarFr;

    switch (window.localStorage.getItem('lng')) {
        case 'fr':
            fullCalendarLocale = rcCalendarFr;
            moment.locale('fr');
            break;
        case 'en':
            fullCalendarLocale = rcCalendarEn;
            moment.locale('en');
            break;

        default:
            moment.locale('fr');
            break;
    }

    const periodCompute = (sanitizedPeriods, sanitizedExclusions) => {
        const currentDay = calendarYear.clone().startOf('year');
        const dayStyles = [];

        // Make array of each days in this years, to put style if in period or in exclusion
        forEach(Object.keys([...Array(calendarYear.clone().endOf('year').dayOfYear())]), () => {
            const dayExclusion = reduce(
                sanitizedExclusions,
                (currentExclusion, exclusion) =>
                    currentDay.isBetween(exclusion.startDate, exclusion.endDate, 'day', '[]') &&
                    (!currentExclusion || exclusion.startDate.isSameOrAfter(currentExclusion.startDate, 'day'))
                        ? exclusion
                        : currentExclusion,
                null
            );
            if (dayExclusion) {
                dayStyles.push({
                    selector: `.${calendarContainerClass} div[data-date="${currentDay
                        .locale('fr')
                        .format('D MMMM YYYY')}"]`,
                    styles: {
                        border: `solid 2px ${dayExclusion.dayDefinitions[0].color}`,
                        background: '',
                        color: '#666',
                        padding: 'unset'
                    },
                    classes: ['day-excluded'],
                });
            } else {
                const dayPeriod = reduce(
                    sanitizedPeriods,
                    (currentPeriod, period) =>
                        period.startDate.isSameOrBefore(currentDay, 'day') &&
                        (!currentPeriod || period.startDate.isSameOrAfter(currentPeriod.startDate, 'day'))
                            ? period
                            : currentPeriod,
                    null
                );
                if (dayPeriod) {
                    const currentDayDefinition =
                        dayPeriod.dayDefinitions[
                            currentDay.diff(dayPeriod.startDate, 'days') % dayPeriod.dayDefinitions.length
                        ];
                    dayStyles.push({
                        selector: `.${calendarContainerClass} div[data-date="${currentDay
                            .locale('fr')
                            .format('D MMMM YYYY')}"]`,
                        styles: {
                            border: '',
                            background: currentDayDefinition ? currentDayDefinition.color : 'transparent',
                            color: currentDayDefinition ? chooseDayNumberColor(currentDayDefinition.color) : '#666',
                        },
                        classes: [],
                    });
                } else {
                    dayStyles.push({
                        selector: `.${calendarContainerClass} div[data-date="${currentDay
                            .locale('fr')
                            .format('D MMMM YYYY')}"]`,
                        styles: {
                            border: '',
                            background: 'transparent',
                            color: '#666',
                        },
                        classes: [],
                    });
                }
            }
            currentDay.add(1, 'day');
        });
        setLoading(false);
        // Apply style to all days
        forEach(dayStyles, (dayStyle) => {
            const $dayInCalendar = Array.from(document.querySelectorAll(dayStyle.selector)).find((element) =>
                element.closest('.rc-calendar-cell:not(.rc-calendar-next-month-btn-day)')
            );
            if ($dayInCalendar) {
                Object.assign($dayInCalendar.style, dayStyle.styles);
                if (dayStyle.classes) {
                    forEach(dayStyle.classes, (dayClass) => {
                        $dayInCalendar.classList.add(dayClass);
                    });
                }
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        // Convert dates from API to moment dates
        const sanitizedPeriods = reduce(
            selectedCalendar ? Object.keys(selectedCalendar.periods) : [],
            (agg, periodStart) => {
                agg.push({
                    id: selectedCalendar.periods[periodStart].id,
                    name: selectedCalendar.periods[periodStart].name,
                    startDate: moment(periodStart),
                    dayDefinitions: selectedCalendar.periods[periodStart].dayDefinitions,
                });

                return agg;
            },
            []
        );
        const sanitizedExclusions = map(selectedCalendar ? selectedCalendar.specificPeriods : [], (exclusion) =>
            Object.assign(exclusion, {
                startDate: moment(exclusion.startDate, 'YYYY-MM-DD'),
                endDate: moment(exclusion.endDate, 'YYYY-MM-DD'),
            })
        );
        periodCompute(sanitizedPeriods, sanitizedExclusions);
    }, [selectedCalendar, periodsTab, calendarYear]);

    const onSelect = useCallback((value) => {
        if (!isAdmin) {
            return;
        }
        if (periodsTab === 'exclusions') {
            if (selectedStartDate) {
                if (moment(value).isBefore(selectedStartDate)) {
                    notificationError(
                        'Vous devez choisir une date après celle sélectionnée.',
                        'Erreur sélection des dates de la période spécifique'
                    );
                } else {
                    setModalContent({
                        key: 'calendar-add-exclusion',
                        title: "Modification des calendriers - Ajout d'une exception sur un calendrier",
                        body: (
                            <CalendarsTabContextProvider>
                                <CalendarAddExclusionForm
                                    calendar={selectedCalendar}
                                    startDate={selectedStartDate}
                                    endDate={moment(value)}
                                />
                            </CalendarsTabContextProvider>
                        ),
                    });
                }
            } else {
                selectedStartDate = moment(value);
                const currentDateCell = document.querySelector(
                    `.${calendarContainerClass} div[data-date="${selectedStartDate
                        .locale('fr')
                        .format('D MMMM YYYY')}"]:not(.rc-calendar-next-month-btn-day)`
                );
                if (currentDateCell) {
                    currentDateCell.style.border = 'dashed 2px #fbbe3f';
                    currentDateCell.style.padding = 'unset';
                }
            }
        } else if (periodsTab === 'periods') {
            setModalContent({
                key: 'calendar-add-period',
                title: t('calendars_calendars.calendar_add_period_calendar'),
                body: (
                    <CalendarsTabContextProvider>
                        <CalendarAddPeriodForm
                            periods={periods}
                            calendar={selectedCalendar}
                            startDate={moment(value)}
                        />
                    </CalendarsTabContextProvider>
                ),
            });
        }
    }, [periodsTab, selectedStartDate]);

    const updateCalendarYear = (value) => {
        setCalendarYear(calendarYear.clone().add(value, 'year'));
    };

    const renderCalendar = useMemo(() => {
        const calendars = [];
        let calendarsChunk = [];
        for (let counter = 0; counter < 12; counter += 1) {
            const month = calendarYear.clone().set('month', counter);
            const weekNumbers = [];
            const weekPivot = month.clone().startOf('month');
            while (weekPivot.isSame(month, 'month')) {
                weekNumbers.push(weekPivot.format('WW'));
                weekPivot.add(1, 'week');
            }
            while (weekNumbers.length < 6) {
                weekNumbers.push(weekPivot.format('WW'));
                weekPivot.add(1, 'week');
            }

            calendarsChunk.push(
                <CalendarWrapper
                    key={`calendar-resource-${calendarYear.year()}-${counter}`}
                    id={`calendar-resource-${calendarYear.year()}-${counter}`}
                >
                    <div className='flex justify-center'>

                    <label className=''>{month.clone().format('MMMM')}</label>
                    </div>
                    {loading ? (
                        <LoadingSpin />
                    ) : (
                        <div className="flex items-end">
                            <MonthWeekNumbers className="flex flex-col text-xs">
                                <WeekNumberHeader />
                                {weekNumbers.map((weekNumber) => (
                                    <WeekNumber className="text-secondary px-1">{weekNumber}</WeekNumber>
                                ))}
                            </MonthWeekNumbers>
                            <FullCalendar
                                locale={fullCalendarLocale}
                                dateCellRender={(current) =>  <div className="rc-calendar-date" style={{padding: '1px'}} data-date={`${current.clone().locale('fr')
                                .format('D MMMM YYYY')}`}>{current.format('D')}</div>}
                                style={{ margin: 10 }}
                                Select={Select}
                                fullscreen={false}
                                onSelect={onSelect}
                                defaultValue={month}
                            />
                        </div>
                    )}
                </CalendarWrapper>
            );

            if (calendarsChunk.length === 3) {
                calendars.push(calendarsChunk);
                calendarsChunk = [];
            }
        }

        return calendars.map((chunkCalendar) => (
            <div key={generateString(5)} className="flex justify-between mb-2">
                {chunkCalendar.map((calendar) => calendar)}
            </div>
        ));
    }, [calendarYear, periodsTab]);

    return selectedCalendar ? (
        <div>
            <div className="w-full flex justify-between items-center pb-3">
                <Button type="primary" onClick={() => updateCalendarYear(-1)}>
                    &lt;
                </Button>
                <CalendarYearLabel>{calendarYear.year()}</CalendarYearLabel>
                <Button type="primary" onClick={() => updateCalendarYear(1)}>
                    &gt;
                </Button>
            </div>
            <div className={`${calendarContainerClass}`}>{renderCalendar}</div>
        </div>
    ) : (
        <div className="flex items-center justify-center h-full">
            <Empty description="Aucun calendrier sélectionné" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
    );
};
export default CalendarYearCalendars;
